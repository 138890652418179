.loadmeRect {
    width: 50px;
    height: 40px;
    position: fixed;
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -25px;
    text-align: center;
    font-size: 10px;
    z-index: 100;
    .loadmeRectChild {
        background-color: @load-color;
        height: 100%;
        width: 6px;
        display: inline-block;
        animation: loadmeRect-animate 1.2s infinite ease-in-out;
    }
    .loadmeRect2 {
        animation-delay: -1.1s;
    }
    .loadmeRect3 {
        animation-delay: -1.0s;
    }
    .loadmeRect4 {
        animation-delay: -0.9s;
    }
    .loadmeRect5 {
        animation-delay: -0.8s;
    }
}

@keyframes loadmeRect-animate {
    0%,
    40%,
    100% {
        transform: scaleY(0.4);
    }
    20% {
        transform: scaleY(1.0);
    }
}
