.loadme-mask {
  background-color: #000;
  width: 100px;
  height: 100px;
  position: fixed;
  z-index: 99;
  left: 50%;
  top: 50%;
  margin: -50px 0 0 -50px;
  border-radius: 10px;
  opacity: 0.5;
}
.loadme-circular,
.loadme-circular:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loadme-circular {
  width: 40px;
  height: 40px;
  position: fixed;
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  z-index: 100;
  font-size: 10px;
  text-indent: -9999em;
  border-top: 1px solid rgba(237, 237, 237, 0.8);
  border-right: 1px solid rgba(237, 237, 237, 0.8);
  border-bottom: 1px solid rgba(237, 237, 237, 0.8);
  border-left: 1px solid #fff;
  transform: translateZ(0);
  animation: loadme-circular-animate 1.1s infinite linear;
}
@keyframes loadme-circular-animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loadme-rotateplane {
  width: 40px;
  height: 40px;
  background-color: #fff;
  position: fixed;
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  animation: loadme-rotateplane-animate 1.2s infinite ease-in-out;
  z-index: 100;
}
@keyframes loadme-rotateplane-animate {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
.loadme-cube-grid {
  width: 50px;
  height: 50px;
  position: fixed;
  left: 50%;
  top: 50%;
  margin: -25px 0 0 -25px;
  z-index: 100;
}
.loadme-cube-grid .loadme-cubeGrid {
  width: 33%;
  height: 33%;
  background-color: #fff;
  float: left;
  animation: loadme-cubeGrid-animate 1.3s infinite ease-in-out;
}
.loadme-cube-grid .loadme-cubeGrid1 {
  animation-delay: 0.2s;
}
.loadme-cube-grid .loadme-cubeGrid2 {
  animation-delay: 0.3s;
}
.loadme-cube-grid .loadme-cubeGrid3 {
  animation-delay: 0.4s;
}
.loadme-cube-grid .loadme-cubeGrid4 {
  animation-delay: 0.1s;
}
.loadme-cube-grid .loadme-cubeGrid5 {
  animation-delay: 0.2s;
}
.loadme-cube-grid .loadme-cubeGrid6 {
  animation-delay: 0.3s;
}
.loadme-cube-grid .loadme-cubeGrid7 {
  animation-delay: 0s;
}
.loadme-cube-grid .loadme-cubeGrid8 {
  animation-delay: 0.1s;
}
.loadme-cube-grid .loadme-cubeGrid9 {
  animation-delay: 0.2s;
}
@keyframes loadme-cubeGrid-animate {
  0%,
  70%,
  100% {
    transform: scale3D(1, 1, 1);
  }
  35% {
    transform: scale3D(0, 0, 1);
  }
}
.loadme-circleBounce {
  width: 40px;
  height: 40px;
  position: fixed;
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  z-index: 100;
}
.loadme-circleBounce1,
.loadme-circleBounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: loadme-circleBounce-animate 2s infinite ease-in-out;
}
.loadme-circleBounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
@keyframes loadme-circleBounce-animate {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
.loadmeRect {
  width: 50px;
  height: 40px;
  position: fixed;
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -25px;
  text-align: center;
  font-size: 10px;
  z-index: 100;
}
.loadmeRect .loadmeRectChild {
  background-color: #fff;
  height: 100%;
  width: 6px;
  display: inline-block;
  animation: loadmeRect-animate 1.2s infinite ease-in-out;
}
.loadmeRect .loadmeRect2 {
  animation-delay: -1.1s;
}
.loadmeRect .loadmeRect3 {
  animation-delay: -1s;
}
.loadmeRect .loadmeRect4 {
  animation-delay: -0.9s;
}
.loadmeRect .loadmeRect5 {
  animation-delay: -0.8s;
}
@keyframes loadmeRect-animate {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
}
.loadme-cube {
  width: 40px;
  height: 40px;
  position: fixed;
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  z-index: 100;
}
.loadme-cube1,
.loadme-cube2 {
  background-color: #fff;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  left: 0;
  animation: sk-cubemove 1.8s infinite ease-in-out;
}
.loadme-cube2 {
  animation-delay: -0.9s;
}
@keyframes sk-cubemove {
  25% {
    transform: translateX(25px) rotate(-90deg) scale(0.5);
  }
  50% {
    transform: translateX(25px) translateY(25px) rotate(-179deg);
  }
  50.1% {
    transform: translateX(25px) translateY(25px) rotate(-180deg);
  }
  75% {
    transform: translateX(0px) translateY(25px) rotate(-270deg) scale(0.5);
  }
  100% {
    transform: rotate(-360deg);
  }
}
.loadme-scaleout {
  width: 40px;
  height: 40px;
  background-color: #fff;
  position: fixed;
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  border-radius: 100%;
  animation: loadme-scaleout-animate 1s infinite ease-in-out;
  z-index: 100;
}
@keyframes loadme-scaleout-animate {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
.loadme-dot {
  width: 40px;
  height: 40px;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  animation: loadme-dot-rotate 2s infinite linear;
  z-index: 100;
}
.loadme-dot1,
.loadme-dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: #fff;
  border-radius: 100%;
  animation: loadme-dot-bounce 2s infinite ease-in-out;
}
.loadme-dot2 {
  top: auto;
  bottom: 0;
  animation-delay: -1s;
}
@keyframes loadme-dot-rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes loadme-dot-bounce {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
.loadme-bounced {
  width: 70px;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  margin: 0 0 0 -35px;
  z-index: 100;
}
.loadme-bounced .loadme-bounced-child {
  width: 18px;
  height: 18px;
  background-color: #fff;
  border-radius: 100%;
  display: inline-block;
  animation: loadme-bouncedelay-animate 1.4s infinite ease-in-out both;
}
.loadme-bounced .loadme-bounced1 {
  animation-delay: -0.32s;
}
.loadme-bounced .loadme-bounced2 {
  animation-delay: -0.16s;
}
@keyframes loadme-bouncedelay-animate {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
.loadme-circlePoint {
  position: fixed;
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  width: 40px;
  height: 40px;
  z-index: 100;
}
.loadme-circlePoint .loadme-circlePoint-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.loadme-circlePoint .loadme-circlePoint-child:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #fff;
  border-radius: 100%;
  animation: loadme-circlePoint-animate 1.2s infinite ease-in-out both;
}
.loadme-circlePoint .loadme-circlePoint2 {
  transform: rotate(30deg);
}
.loadme-circlePoint .loadme-circlePoint3 {
  transform: rotate(60deg);
}
.loadme-circlePoint .loadme-circlePoint4 {
  transform: rotate(90deg);
}
.loadme-circlePoint .loadme-circlePoint5 {
  transform: rotate(120deg);
}
.loadme-circlePoint .loadme-circlePoint6 {
  transform: rotate(150deg);
}
.loadme-circlePoint .loadme-circlePoint7 {
  transform: rotate(180deg);
}
.loadme-circlePoint .loadme-circlePoint8 {
  transform: rotate(210deg);
}
.loadme-circlePoint .loadme-circlePoint9 {
  transform: rotate(240deg);
}
.loadme-circlePoint .loadme-circlePoint10 {
  transform: rotate(270deg);
}
.loadme-circlePoint .loadme-circlePoint11 {
  transform: rotate(300deg);
}
.loadme-circlePoint .loadme-circlePoint12 {
  transform: rotate(330deg);
}
.loadme-circlePoint .loadme-circlePoint2:before {
  animation-delay: -1.1s;
}
.loadme-circlePoint .loadme-circlePoint3:before {
  animation-delay: -1s;
}
.loadme-circlePoint .loadme-circlePoint4:before {
  animation-delay: -0.9s;
}
.loadme-circlePoint .loadme-circlePoint5:before {
  animation-delay: -0.8s;
}
.loadme-circlePoint .loadme-circlePoint6:before {
  animation-delay: -0.7s;
}
.loadme-circlePoint .loadme-circlePoint7:before {
  animation-delay: -0.6s;
}
.loadme-circlePoint .loadme-circlePoint8:before {
  animation-delay: -0.5s;
}
.loadme-circlePoint .loadme-circlePoint9:before {
  animation-delay: -0.4s;
}
.loadme-circlePoint .loadme-circlePoint10:before {
  animation-delay: -0.3s;
}
.loadme-circlePoint .loadme-circlePoint11:before {
  animation-delay: -0.2s;
}
.loadme-circlePoint .loadme-circlePoint12:before {
  animation-delay: -0.1s;
}
@keyframes loadme-circlePoint-animate {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
.loadme-fadingCircle {
  width: 40px;
  height: 40px;
  position: fixed;
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  z-index: 100;
}
.loadme-fadingCircle .loadme-fadingCircle-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.loadme-fadingCircle .loadme-fadingCircle-child:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #fff;
  border-radius: 100%;
  animation: loadme-fadingCircle 1.2s infinite ease-in-out both;
}
.loadme-fadingCircle .loadme-fadingCircle-child2 {
  transform: rotate(30deg);
}
.loadme-fadingCircle .loadme-fadingCircle-child3 {
  transform: rotate(60deg);
}
.loadme-fadingCircle .loadme-fadingCircle-child4 {
  transform: rotate(90deg);
}
.loadme-fadingCircle .loadme-fadingCircle-child5 {
  transform: rotate(120deg);
}
.loadme-fadingCircle .loadme-fadingCircle-child6 {
  transform: rotate(150deg);
}
.loadme-fadingCircle .loadme-fadingCircle-child7 {
  transform: rotate(180deg);
}
.loadme-fadingCircle .loadme-fadingCircle-child8 {
  transform: rotate(210deg);
}
.loadme-fadingCircle .loadme-fadingCircle-child9 {
  transform: rotate(240deg);
}
.loadme-fadingCircle .loadme-fadingCircle-child10 {
  transform: rotate(270deg);
}
.loadme-fadingCircle .loadme-fadingCircle-child11 {
  transform: rotate(300deg);
}
.loadme-fadingCircle .loadme-fadingCircle-child12 {
  transform: rotate(330deg);
}
.loadme-fadingCircle .loadme-fadingCircle-child2:before {
  animation-delay: -1.1s;
}
.loadme-fadingCircle .loadme-fadingCircle-child3:before {
  animation-delay: -1s;
}
.loadme-fadingCircle .loadme-fadingCircle-child4:before {
  animation-delay: -0.9s;
}
.loadme-fadingCircle .loadme-fadingCircle-child5:before {
  animation-delay: -0.8s;
}
.loadme-fadingCircle .loadme-fadingCircle-child6:before {
  animation-delay: -0.7s;
}
.loadme-fadingCircle .loadme-fadingCircle-child7:before {
  animation-delay: -0.6s;
}
.loadme-fadingCircle .loadme-fadingCircle-child8:before {
  animation-delay: -0.5s;
}
.loadme-fadingCircle .loadme-fadingCircle-child9:before {
  animation-delay: -0.4s;
}
.loadme-fadingCircle .loadme-fadingCircle-child10:before {
  animation-delay: -0.3s;
}
.loadme-fadingCircle .loadme-fadingCircle-child11:before {
  animation-delay: -0.2s;
}
.loadme-fadingCircle .loadme-fadingCircle-child12:before {
  animation-delay: -0.1s;
}
@keyframes loadme-fadingCircle {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
.loadme-foldingCube {
  width: 40px;
  height: 40px;
  transform: rotateZ(45deg);
  position: fixed;
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  z-index: 100;
}
.loadme-foldingCube .loadme-foldingCube-child {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  transform: scale(1.1);
}
.loadme-foldingCube .loadme-foldingCube-child:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  animation: loadme-foldCubeAngle-animate 2.4s infinite linear both;
  transform-origin: 100% 100%;
}
.loadme-foldingCube .loadme-foldingCube-child2 {
  transform: scale(1.1) rotateZ(90deg);
}
.loadme-foldingCube .loadme-foldingCube-child3 {
  transform: scale(1.1) rotateZ(180deg);
}
.loadme-foldingCube .loadme-foldingCube-child4 {
  transform: scale(1.1) rotateZ(270deg);
}
.loadme-foldingCube .loadme-foldingCube-child2:before {
  animation-delay: 0.3s;
}
.loadme-foldingCube .loadme-foldingCube-child3:before {
  animation-delay: 0.6s;
}
.loadme-foldingCube .loadme-foldingCube-child4:before {
  animation-delay: 0.9s;
}
@keyframes loadme-foldCubeAngle-animate {
  0%,
  10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
.loadmeLove {
  position: fixed;
  width: 50px;
  height: 50px;
  left: 50%;
  top: 50%;
  margin: -25px 0 0 -25px;
  z-index: 100;
  background-color: #fff;
  animation: loadme-love-animate 0.8s infinite alternate;
  animation-timing-function: ease-in;
}
.loadmeLove:before,
.loadmeLove:after {
  position: absolute;
  width: 50px;
  height: 50px;
  content: '';
  border-radius: 50%;
  background-color: #fff;
}
.loadmeLove:before {
  bottom: 0px;
  left: -25px;
}
.loadmeLove:after {
  top: -25px;
  right: 0px;
}
@keyframes loadme-love-animate {
  0% {
    transform: scale(1, 1) rotate(45deg);
  }
  100% {
    transform: scale(0.5, 0.5) rotate(45deg);
  }
}
.loadmeClock {
  height: 70px;
  width: 110px;
  position: fixed;
  left: 50%;
  top: 50%;
  margin: -35px 0 0 -55px;
  z-index: 100;
}
.loadmeClock-body .loadmeClock-pendulum {
  height: 70px;
  animation-duration: 1s;
  animation-name: loadme-ticktock;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-direction: alternate;
  animation-fill-mode: both;
  animation-play-state: running;
  transform-origin: 50% -70%;
}
.loadmeClock-pendulum .loadmePendulum-stick {
  height: 70%;
  width: 6px;
  margin: 0 auto;
  background-color: #fff;
}
.loadmeClock-pendulum .loadmePendulum-body {
  height: 20px;
  width: 20px;
  border-radius: 40px;
  margin: 0 auto;
  margin-top: -2px;
  background-color: #fff;
}
@keyframes loadme-ticktock {
  0% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(-15deg);
  }
}
.loadme-ios {
  width: 40px;
  height: 40px;
  position: fixed;
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  z-index: 100;
  font-size: 10px;
  animation: loadme-ios-animate 1s steps(12) infinite;
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMjAiIGhlaWdodD0iMTIwIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgxMDB2MTAwSDB6Ii8+PHJlY3Qgd2lkdGg9IjciIGhlaWdodD0iMjAiIHg9IjQ2LjUiIHk9IjQwIiBmaWxsPSIjRTlFOUU5IiByeD0iNSIgcnk9IjUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgLTMwKSIvPjxyZWN0IHdpZHRoPSI3IiBoZWlnaHQ9IjIwIiB4PSI0Ni41IiB5PSI0MCIgZmlsbD0iIzk4OTY5NyIgcng9IjUiIHJ5PSI1IiB0cmFuc2Zvcm09InJvdGF0ZSgzMCAxMDUuOTggNjUpIi8+PHJlY3Qgd2lkdGg9IjciIGhlaWdodD0iMjAiIHg9IjQ2LjUiIHk9IjQwIiBmaWxsPSIjOUI5OTlBIiByeD0iNSIgcnk9IjUiIHRyYW5zZm9ybT0icm90YXRlKDYwIDc1Ljk4IDY1KSIvPjxyZWN0IHdpZHRoPSI3IiBoZWlnaHQ9IjIwIiB4PSI0Ni41IiB5PSI0MCIgZmlsbD0iI0EzQTFBMiIgcng9IjUiIHJ5PSI1IiB0cmFuc2Zvcm09InJvdGF0ZSg5MCA2NSA2NSkiLz48cmVjdCB3aWR0aD0iNyIgaGVpZ2h0PSIyMCIgeD0iNDYuNSIgeT0iNDAiIGZpbGw9IiNBQkE5QUEiIHJ4PSI1IiByeT0iNSIgdHJhbnNmb3JtPSJyb3RhdGUoMTIwIDU4LjY2IDY1KSIvPjxyZWN0IHdpZHRoPSI3IiBoZWlnaHQ9IjIwIiB4PSI0Ni41IiB5PSI0MCIgZmlsbD0iI0IyQjJCMiIgcng9IjUiIHJ5PSI1IiB0cmFuc2Zvcm09InJvdGF0ZSgxNTAgNTQuMDIgNjUpIi8+PHJlY3Qgd2lkdGg9IjciIGhlaWdodD0iMjAiIHg9IjQ2LjUiIHk9IjQwIiBmaWxsPSIjQkFCOEI5IiByeD0iNSIgcnk9IjUiIHRyYW5zZm9ybT0icm90YXRlKDE4MCA1MCA2NSkiLz48cmVjdCB3aWR0aD0iNyIgaGVpZ2h0PSIyMCIgeD0iNDYuNSIgeT0iNDAiIGZpbGw9IiNDMkMwQzEiIHJ4PSI1IiByeT0iNSIgdHJhbnNmb3JtPSJyb3RhdGUoLTE1MCA0NS45OCA2NSkiLz48cmVjdCB3aWR0aD0iNyIgaGVpZ2h0PSIyMCIgeD0iNDYuNSIgeT0iNDAiIGZpbGw9IiNDQkNCQ0IiIHJ4PSI1IiByeT0iNSIgdHJhbnNmb3JtPSJyb3RhdGUoLTEyMCA0MS4zNCA2NSkiLz48cmVjdCB3aWR0aD0iNyIgaGVpZ2h0PSIyMCIgeD0iNDYuNSIgeT0iNDAiIGZpbGw9IiNEMkQyRDIiIHJ4PSI1IiByeT0iNSIgdHJhbnNmb3JtPSJyb3RhdGUoLTkwIDM1IDY1KSIvPjxyZWN0IHdpZHRoPSI3IiBoZWlnaHQ9IjIwIiB4PSI0Ni41IiB5PSI0MCIgZmlsbD0iI0RBREFEQSIgcng9IjUiIHJ5PSI1IiB0cmFuc2Zvcm09InJvdGF0ZSgtNjAgMjQuMDIgNjUpIi8+PHJlY3Qgd2lkdGg9IjciIGhlaWdodD0iMjAiIHg9IjQ2LjUiIHk9IjQwIiBmaWxsPSIjRTJFMkUyIiByeD0iNSIgcnk9IjUiIHRyYW5zZm9ybT0icm90YXRlKC0zMCAtNS45OCA2NSkiLz48L3N2Zz4=) no-repeat;
  background-size: 100%;
}
@keyframes loadme-ios-animate {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
