.loadme-circlePoint {
    position: fixed;
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
    width: 40px;
    height: 40px;
    z-index: 100;
    .loadme-circlePoint-child {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }
    .loadme-circlePoint-child:before {
        content: '';
        display: block;
        margin: 0 auto;
        width: 15%;
        height: 15%;
        background-color: @load-color;
        border-radius: 100%;
        animation: loadme-circlePoint-animate 1.2s infinite ease-in-out both;
    }
    .loadme-circlePoint2 {
        transform: rotate(30deg);
    }
    .loadme-circlePoint3 {
        transform: rotate(60deg);
    }
    .loadme-circlePoint4 {
        transform: rotate(90deg);
    }
    .loadme-circlePoint5 {
        transform: rotate(120deg);
    }
    .loadme-circlePoint6 {
        transform: rotate(150deg);
    }
    .loadme-circlePoint7 {
        transform: rotate(180deg);
    }
    .loadme-circlePoint8 {
        transform: rotate(210deg);
    }
    .loadme-circlePoint9 {
        transform: rotate(240deg);
    }
    .loadme-circlePoint10 {
        transform: rotate(270deg);
    }
    .loadme-circlePoint11 {
        transform: rotate(300deg);
    }
    .loadme-circlePoint12 {
        transform: rotate(330deg);
    }
    .loadme-circlePoint2:before {
        animation-delay: -1.1s;
    }
    .loadme-circlePoint3:before {
        animation-delay: -1s;
    }
    .loadme-circlePoint4:before {
        animation-delay: -0.9s;
    }
    .loadme-circlePoint5:before {
        animation-delay: -0.8s;
    }
    .loadme-circlePoint6:before {
        animation-delay: -0.7s;
    }
    .loadme-circlePoint7:before {
        animation-delay: -0.6s;
    }
    .loadme-circlePoint8:before {
        animation-delay: -0.5s;
    }
    .loadme-circlePoint9:before {
        animation-delay: -0.4s;
    }
    .loadme-circlePoint10:before {
        animation-delay: -0.3s;
    }
    .loadme-circlePoint11:before {
        animation-delay: -0.2s;
    }
    .loadme-circlePoint12:before {
        animation-delay: -0.1s;
    }
}

@keyframes loadme-circlePoint-animate {
    0%,
    80%,
    100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
}
