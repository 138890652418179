.loadme-dot {
    width: 40px;
    height: 40px;
    text-align: center;
    position: fixed;
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
    animation: loadme-dot-rotate 2.0s infinite linear;
    z-index: 100;
}

.loadme-dot1,
.loadme-dot2 {
    width: 60%;
    height: 60%;
    display: inline-block;
    position: absolute;
    top: 0;
    background-color: @load-color;
    border-radius: 100%;
    animation: loadme-dot-bounce 2.0s infinite ease-in-out;
}

.loadme-dot2 {
    top: auto;
    bottom: 0;
    animation-delay: -1.0s;
}

@keyframes loadme-dot-rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes loadme-dot-bounce {
    0%,
    100% {
        transform: scale(0.0);
    }
    50% {
        transform: scale(1.0);
    }
}
