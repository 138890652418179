.loadmeLove {
    position: fixed;
    width: 50px;
    height: 50px;
    left: 50%;
    top: 50%;
    margin: -25px 0 0 -25px;
    z-index: 100;
    background-color: @load-color;
    animation: loadme-love-animate 0.8s infinite alternate;
    animation-timing-function: ease-in;
}

.loadmeLove:before,
.loadmeLove:after {
    position: absolute;
    width: 50px;
    height: 50px;
    content: '';
    border-radius: 50%;
    background-color: @load-color;
}

.loadmeLove:before {
    bottom: 0px;
    left: -25px;
}

.loadmeLove:after {
    top: -25px;
    right: 0px;
}

@keyframes loadme-love-animate {
    0% {
        transform:scale(1,1) rotate(45deg);
    }
    100% {
        transform:scale(0.5,0.5) rotate(45deg);
    }
}

