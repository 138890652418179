.loadme-circular,
.loadme-circular:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}

.loadme-circular {
    width: 40px;
    height: 40px;
    position: fixed;
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
    z-index: 100;
    font-size: 10px;
    text-indent: -9999em;
    border-top: 1px solid rgba(237, 237, 237, 0.8);
    border-right: 1px solid rgba(237, 237, 237, 0.8);
    border-bottom: 1px solid rgba(237, 237, 237, 0.8);
    border-left: 1px solid @load-color;
    transform: translateZ(0);
    animation: loadme-circular-animate 1.1s infinite linear;
}

@keyframes loadme-circular-animate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
