.loadmeClock {
    height: 70px;
    width: 110px;
    position: fixed;
    left: 50%;
    top: 50%;
    margin: -35px 0 0 -55px;
    z-index: 100;
}

.loadmeClock-body .loadmeClock-pendulum {
    height: 70px;
    animation-duration: 1s;
    animation-name: loadme-ticktock;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
    animation-direction: alternate;
    animation-fill-mode: both;
    animation-play-state: running;
    transform-origin: 50% -70%;
}

.loadmeClock-pendulum .loadmePendulum-stick {
    height: 70%;
    width: 6px;
    margin: 0 auto;
    background-color: @load-color;
}

.loadmeClock-pendulum .loadmePendulum-body {
    height: 20px;
    width: 20px;
    border-radius: 40px;
    margin: 0 auto;
    margin-top: -2px;
    background-color: @load-color;
}

@keyframes loadme-ticktock {
    0% {
        transform: rotate(15deg);
    }
    100% {
        transform: rotate(-15deg);
    }
}
