.loadme-rotateplane {
    width: 40px;
    height: 40px;
    background-color: @load-color;
    position: fixed;
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
    animation: loadme-rotateplane-animate 1.2s infinite ease-in-out;
    z-index: 100;
}

@keyframes loadme-rotateplane-animate {
    0% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }
    50% {
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }
    100% {
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}
