.loadme-scaleout {
    width: 40px;
    height: 40px;
    background-color: @load-color;
    position: fixed;
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
    border-radius: 100%;
    animation: loadme-scaleout-animate 1.0s infinite ease-in-out;
    z-index: 100;
}

@keyframes loadme-scaleout-animate {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1.0);
        opacity: 0;
    }
}
