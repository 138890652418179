.loadme-mask {
    background-color: @mask-color;
    width: 100px;
    height: 100px;
    position: fixed;
    z-index: 99;
    left: 50%;
    top: 50%;
    margin: -50px 0 0 -50px;
    border-radius: 10px;
    opacity: @mask-opacity;
}
