.loadme-cube {
    width: 40px;
    height: 40px;
    position: fixed;
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
    z-index: 100;
}

.loadme-cube1,
.loadme-cube2 {
    background-color: @load-color;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 0;
    left: 0;
    animation: sk-cubemove 1.8s infinite ease-in-out;
}

.loadme-cube2 {
    animation-delay: -0.9s;
}

@keyframes sk-cubemove {
    25% {
        transform: translateX(25px) rotate(-90deg) scale(0.5);
    }
    50% {
        transform: translateX(25px) translateY(25px) rotate(-179deg);
    }
    50.1% {
        transform: translateX(25px) translateY(25px) rotate(-180deg);
    }
    75% {
        transform: translateX(0px) translateY(25px) rotate(-270deg) scale(0.5);
    }
    100% {
        transform: rotate(-360deg);
    }
}
